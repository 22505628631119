<template>
  <div>
    <Combination />
  </div>
</template>

<script>
import Combination from './Combination';
export default {
  name: 'Info',
  components: { Combination },
};
</script>

<style scoped></style>
