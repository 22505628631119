<template>
  <div class="info_box">
    <div>
      <i class="el-icon-key" style="color: #2362fb" />
      <span class="info_title">账号密码</span>
    </div>
    <div class="form_box">
      <el-form
        size="small"
        label-position="left"
        label-width="120px"
        :model="formData"
      >
        <el-form-item required label="账号">
          <el-input v-model="formData.account" />
        </el-form-item>
        <el-form-item required label="密码">
          <el-input v-model="formData.password" />
        </el-form-item>
        <el-form-item required label="确认密码">
          <el-input v-model="formData.rep_password" />
        </el-form-item>
        <el-form-item>
          <el-button class="finish_btn">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountInfo',
  data() {
    return {
      formData: {
        account: '',
        password: '',
        rep_password: '',
      },
    };
  },
};
</script>

<style scoped>
.info_title {
  font-weight: 700;
  padding-left: 10px;
}
.info_box {
  padding: 20px 10px;
}
.form_box {
  width: 400px;
  padding: 20px 10px;
}
.finish_btn {
  background-color: #2362fb;
  color: white;
  width: 100%;
}
</style>
