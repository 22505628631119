<template>
  <div class="info_box">
    <div>
      <i class="el-icon-user-solid" style="color: #2362fb" />
      <span class="info_title">个人信息</span>
    </div>
    <div class="form_box">
      <el-form
        size="small"
        label-position="left"
        label-width="120px"
        :model="formData"
      >
        <el-form-item label="头像">
          <el-row>
            <el-col :md="12">
              <el-avatar class="user_avatar" size="medium"> User </el-avatar>
            </el-col>
            <el-col :md="12">
              <div style="line-height: 70px">
                <el-button class="change_avatar_btn" size="small">
                  更换头像
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item required label="姓名">
          <el-input v-model="formData.name" />
        </el-form-item>
        <el-form-item label="手机号(登录名)">
          <el-input disabled v-model="formData.account" />
        </el-form-item>
        <el-form-item label="直属上级">
          <el-input disabled v-model="formData.up" />
        </el-form-item>
        <el-form-item label="性别">
          <el-input v-model="formData.gender" />
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="formData.email" />
        </el-form-item>
        <el-form-item label="部门">
          <el-input disabled v-model="formData.apartment" />
        </el-form-item>
        <el-form-item label="岗位">
          <el-input v-model="formData.position" />
        </el-form-item>
        <el-form-item>
          <el-button class="finish_btn">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonInfo',
  data() {
    return {
      formData: {
        name: 'YJSP',
        account: 'algjadg',
        up: 'hhh',
        gender: '男',
        email: 'adghadg@qq.com',
        apartment: '空手部',
        position: '码农',
      },
    };
  },
};
</script>
<style scoped>
.info_title {
  font-weight: 700;
  padding-left: 10px;
}
.info_box {
  padding: 20px 10px;
}
.form_box {
  width: 400px;
  padding: 20px 10px;
}
.user_avatar {
  background-color: #2362fb;
  height: 70px;
  width: 70px;
  line-height: 70px;
}
.change_avatar_btn {
  border: 1px solid #2362fb;
  color: #2362fb;
}
.change_avatar_btn:hover {
  background-color: #2362fb;
  color: white;
}
.finish_btn {
  background-color: #2362fb;
  color: white;
  width: 100%;
}
</style>
