<template>
  <div>
    <span v-show="current === 'info'">
      <PersonInfo />
    </span>
    <span v-show="current === 'account'">
      <AccountInfo />
    </span>
  </div>
</template>

<script>
import PersonInfo from './PersonInfo';
import AccountInfo from './AccountInfo';
export default {
  name: 'RightContent',
  components: { AccountInfo, PersonInfo },
  props: {
    current: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
