<template>
  <div class="combination_box">
    <!--  左边是选择框和用户名头像之类的东西  -->
    <div class="left_box">
      <div class="full_box">
        <div class="full_box chooser_box">
          <el-row style="padding: 0 20px">
            <el-col :md="5">
              <el-avatar class="user_avatar" size="large"> User </el-avatar>
            </el-col>
            <el-col :md="19">
              <el-avatar style="background: none; color: grey" size="large">
                User
              </el-avatar>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
            <el-col :md="24" :class="{ is_choose: isChoose(current) }">
              <div class="chooser" @click="current = 'info'">
                <i class="el-icon-user" style="margin-right: 7px" />
                <span>个人信息</span>
              </div>
            </el-col>
            <el-col :md="24" :class="{ is_choose: !isChoose(current) }">
              <div class="chooser" @click="current = 'account'">
                <i class="el-icon-key" style="margin-right: 7px" />
                <span>账号密码</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!--  右边是单独封装的展示组件，这里不实用路由的原因是路由嵌套过多，不好处理  -->
    <div class="right_box">
      <RightContent :current="current" />
    </div>
  </div>
</template>

<script>
import RightContent from './RightContent';
export default {
  name: 'Combination',
  components: { RightContent },
  data() {
    return {
      current: 'info',
    };
  },
  methods: {
    isChoose(path) {
      return path === 'info';
    },
  },
};
</script>

<style scoped>
.combination_box {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  height: 100%;
  background-color: antiquewhite;
}
.left_box {
  height: 300px;
  width: 287px;
  background-color: white;
  margin-right: 20px;
}
.right_box {
  width: 655px;
  padding: 10px;
  background-color: white;
}
.user_avatar {
  background-color: #2362fb;
}
.full_box {
  height: 100%;
  width: 100%;
}
.chooser {
  height: 50px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  line-height: 50px;
  padding: 0 20px;
}
.chooser:hover {
  background-color: #f5f6f9;
}
.chooser_box {
  padding: 20px 0;
}
.is_choose {
  background-color: #f5f6f9;
}
</style>
